.App {
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
  height: 100vh;
  margin: 0;
}

h1 {
  margin-bottom: 20px;
}

a {
  display: flex;
  flex-direction: row;
  align-items: center;
  margin-bottom: 10px;
}

a > svg {
  color: black;
  width: 30px;
  height: 30px;
  margin-right: 10px;
}
